.form-control {
    background : #e9ecef;
    border : none;
    border-radius: 0px;
    color : #999;
}
::placeholder {
    color: #999 !important;
}

.btn-form-submit{
    width : 100%;
    height : 35px;
    background : #b1ddd3;
    color : #f1f1f1;
    border : none;
    border-radius : 0px;
}
.btn-form-submit:hover{
    background-color : #8caca4;
}
.btn-form-submit:active{
    background-color : #8caca4 !important;
}
.marcacoes-container{
    h3 {
        color : #b1ddd3;
        font-size : 18px;
    }
    .title {
        text-align : left;
    }
}
@include media-breakpoint-down(sm) {
    .marcacoes-container{
        label{
            margin-bottom : 0px
        }
        h3 {
            font-size : 18px;
        }
        h5{
            font-size : 18px;
        }
        .title {
            padding-left : 0;
            padding-right : 0;
        }
    }
}