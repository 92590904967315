.ul-block-germano{
    padding : 20px 10px; 
    color : #fff;
    list-style-type: cicle;
}
.md-row {
    border-bottom : 3px solid #b1ddd3;
    margin-left : 30px;
    margin-right : 30px;
}
.germano-container{
    text-align : left;
    margin-left : 100px;
    padding-left : 60px;
    margin-top : 120px;
}
.team-title {
    p{
        color : #b1ddd3;
        font-size : 3em;
        line-height:1em;
        
    }
}
@include media-breakpoint-down(sm) {
    .germano-container{
        text-align : left;
        margin-left : 10px;
        padding-left : 60px;
        margin-top : 100px;
    }
    .team-title {
        p{

            font-size : 2em;
            line-height:1em;
        }
    }
}
.md-section{
    h3, h4, h5 {
        color : #b1ddd3;
    }
    li {
        font-size : 14px
    }
    padding-top : 60px;

    text-align : left;
}
@include media-breakpoint-down(sm) {
    .md-row {
        margin-left : 10px;
        margin-right : 10px;
        border-bottom : none;
    }
    .md-container{
        overflow : hidden;
    }
    .md-section{
        ul {
            padding-left: 0;
            list-style-type: none;
        }
        h3, h4, h5 {
            font-size : 18px;
        }
        li {
            font-size : 14px
        }
        padding-top : 20px;
    
        text-align : left;
    }
}