body {
    opacity: 0;
}
.whole-handler{
    position : fixed;
    display : block;

    top : 20px;
    left : 20px;
    z-index : 100000;
}

.navbar-nav{    
    a.active{
        border-bottom: 3px solid #b1ddd3;
    }
    .nav-item:nth-child(1){
        a.active{
            border-color :#b1ddd3;
        }
    }
    .nav-item:nth-child(2){
        a.active{
            border-color :#b1ddd3;
        }
    }
    .nav-item:nth-child(3){
        a.active{
        border-color :#adb0d9;
        }
    }
    .nav-item:nth-child(4){
        a.active{
        border-color :#ffdb96;
        }
    }
    .nav-item:nth-child(5){
        a.active{
        border-color :#ffc965;
        }
    }
    .nav-item:nth-child(6){
        a.active{
        border-color :#b1ddd3;
        }
    }
    .nav-item:nth-child(7){
        a.active{
        border-color :#999;
        }
    }
}  

.myslick-prev:before,
.myslick-next:before
{color: black;}