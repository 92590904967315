$sidebar-background-1: rgba(20, 20, 20, 0.7);
$sidebar-background-2: rgba(103, 154, 140, 0.9);
$sidebar-background-3: rgba(153, 83, 104, 0.9);

html {
  height: 100%;
}
body {
  position: relative;
  font-family: 'Arial', serif;
  height: 100%;
}
.container {
  height: 100%;
}

body.mobile .sidebar-menu {
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
}

body.sidebar-visible {
  .page-sidebar .sidebar-menu .menu-items > li {
    .icon-thumbnail {
      border-left: none;
    }
  }
  .copyright {
    width: 100%;
    right: none;
  }
}
body.sidebar-visible .page-sidebar .scroll-element {
  visibility: visible;
}
body.sidebar-visible .page-sidebar .menu-items .icon-thumbnail {
  -webkit-transform: translate3d(-14px, 0, 0);
  transform: translate3d(-14px, 0, 0);
  -ms-transform: translate(-14px, 0);
}
body.sidebar-visible .page-sidebar .sidebar-header .sidebar-header-controls {
  -webkit-transform: translate3d(48px, 0, 0);
  transform: translate3d(48px, 0, 0);
  -ms-transform: translate(48px, 0);
}

.page-sidebar {
  width: 250px;

  z-index: 1000;
  left: -250px;
  position: fixed;
  bottom: 0;
  top: 0;
  right: auto;
  overflow: hidden;
  -webkit-transition: -webkit-transform 400ms
    cubic-bezier(0.05, 0.74, 0.27, 0.99);
  -moz-transition: -moz-transform 400ms cubic-bezier(0.05, 0.74, 0.27, 0.99);
  -o-transition: -o-transform 400ms cubic-bezier(0.05, 0.74, 0.27, 0.99);
  transition: transform 400ms cubic-bezier(0.05, 0.74, 0.27, 0.99);
  -webkit-backface-visibility: hidden;
  -webkit-perspective: 1000;
  &.opt1 {
    background-color: $sidebar-background-1;
  }
  &.opt2 {
    background-color: $sidebar-background-2;
  }
  &.opt3 {
    background-color: $sidebar-background-3;
  }
  &.opt4 {
    background-color: $sidebar-background-1;
  }
  &.opt5 {
    background-color: $sidebar-background-2;
  }
  &.opt6 {
    background-color: $sidebar-background-3;
  }
}

.navbar-nav .nav-link {
  font-weight : 800;
}

.page-sidebar a,
.page-sidebar button {
  color: white;
}
.page-sidebar a:hover,
.page-sidebar button:hover,
.page-sidebar a:active,
.page-sidebar button:active {
  color: #f1f1f1;
}

.page-sidebar .scroll-element {
  visibility: hidden;
}
.page-sidebar .sidebar-header {
  display: block;
  height: 60px;
  line-height: 60px;
  background-color: #fff;
  border-bottom: 1px solid #e6e6e6;
  color: #fff;
  width: 100%;
  padding: 0 20px;
  padding-left: 30px;
  clear: both;
  z-index: 10;
  position: relative;
}
.page-sidebar .sidebar-header .sidebar-header-controls {
  display: inline-block;
  -webkit-transition: -webkit-transform 0.4s
    cubic-bezier(0.05, 0.74, 0.27, 0.99);
  transition: transform 0.4s cubic-bezier(0.05, 0.74, 0.27, 0.99);
  -webkit-backface-visibility: hidden;
}
.page-sidebar .sidebar-header .sidebar-slide-toggle i {
  -webkit-transition: all 0.12s ease;
  transition: all 0.12s ease;
}
.page-sidebar .sidebar-header .sidebar-slide-toggle.active i {
  -webkit-transform: rotate(-180deg);
  -ms-transform: rotate(-180deg);
  transform: rotate(-180deg);
}
.page-sidebar .close-sidebar {
  position: absolute;
  right: 19px;
  top: 14px;
  padding: 9px;
  z-index: 1;
}
.page-sidebar .close-sidebar > i {
  color: rgba(255, 255, 255, 0.57);
}
.page-sidebar .sidebar-overlay-slide {
  /* Side-bar Top Slider */
  width: 100%;
  height: 100%;
  background-color: #fff;
  display: block;
  z-index: 9;
  padding: 80px 20px 20px 20px;
}
.page-sidebar .sidebar-overlay-slide.from-top {
  top: -100%;
  position: absolute;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
.page-sidebar .sidebar-overlay-slide.from-top.show {
  -webkit-transform: translate(0, 100%);
  -ms-transform: translate(0, 100%);
  transform: translate(0, 100%);
}
.page-sidebar .sidebar-menu {
  /* Side-bar Menu */

  position: relative;
  width: 100%;
}

.page-sidebar .sidebar-menu .outer-tab-nav-section {
  display: inline-block;
  width: 45px;
  position: absolute;
  height: 100%;
}
.page-sidebar .sidebar-menu .menu-items {
  /* Side-bar Menut Items */
  list-style: none;
  margin: 0;
  padding: 0;
  position: relative;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  height: calc(100% - 10px);
  width: 100%;
}

.page-sidebar .sidebar-menu .menu-items > li:after,
.page-sidebar .sidebar-menu .menu-items > li:before {
  display: table;
  content: ' ';
  clear: both;
}
.page-sidebar .sidebar-menu .menu-items > li > a {
  font-family: 'Arial', serif;
  font-weight: 200;
  display: inline-block;
  padding-left: 32px;
  min-height: 50px;
  line-height: 50px;
  font-size: 18px;
  clear: both;
  text-transform: uppercase;
}

.page-sidebar .sidebar-menu .menu-items > li > a > .arrow {
  float: right;
  padding-right: 30px;
}
.page-sidebar .sidebar-menu .menu-items > li > a > .arrow:before {
  display: inline;
  font-size: 16px;
  font-family: FontAwesome;
  content: '\f104';
  font-weight: 300;
  text-shadow: none;
  -webkit-transition: all 0.12s ease;
  transition: all 0.12s ease;
}
.page-sidebar .sidebar-menu .menu-items > li > a > .arrow.open:before {
  -webkit-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  transform: rotate(-90deg);
}
.page-sidebar .sidebar-menu .menu-items > li > a > .badge {
  margin-top: 12px;
}
.page-sidebar .sidebar-menu .menu-items > li > a > .title {
  float: left;
  white-space: nowrap;
  width: 65%;
}
.page-sidebar .sidebar-menu .menu-items > li > a > .details {
  font-size: 12px;
  opacity: 0.4;
  display: block;
  clear: both;
}
.page-sidebar .sidebar-menu .menu-items li.small > a {
  font-size: 1em;
  line-height: 25px;
  min-height: 25px;
}
.page-sidebar .sidebar-menu .menu-items > li > a.detailed > .title {
  line-height: 28px;
}
.page-sidebar .sidebar-menu .menu-items > li > a.detailed > .details {
  line-height: 16px;
}
.page-sidebar .sidebar-menu .menu-items > li.active > ul.sub-menu {
  display: block;
}

.page-sidebar .sidebar-menu .menu-items > li > .icon-thumbnail:first-letter {
  text-transform: uppercase;
}
.page-sidebar .sidebar-menu .menu-items > li > ul.sub-menu > li ul.sub-menu li {
  padding-left: 10px;
  padding-right: 3px;
}
.page-sidebar
  .sidebar-menu
  .menu-items
  > li
  > ul.sub-menu
  > li
  ul.sub-menu
  .icon-thumbnail {
  width: 25px;
  height: 25px;
  line-height: 25px;
  font-size: 10px;
}
.page-sidebar
  .sidebar-menu
  .menu-items
  > li
  > ul.sub-menu
  li
  > a
  > .arrow:before {
  margin-right: 20px;
  display: inline;
  font-size: 16px;
  font-family: FontAwesome;
  content: '\f104';
  font-weight: 300;
  text-shadow: none;
  -webkit-transition: all 0.12s ease;
  transition: all 0.12s ease;
}
.page-sidebar
  .sidebar-menu
  .menu-items
  > li
  > ul.sub-menu
  li
  > a
  > .arrow.open:before {
  margin-right: 18px;
  display: inline;
  font-family: FontAwesome;
  font-size: 16px;
  -webkit-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  transform: rotate(-90deg);
  font-weight: 300;
  text-shadow: none;
}
.page-sidebar .sidebar-menu .menu-items > li ul.sub-menu {
  display: none;
  list-style: none;
  clear: both;
  margin: 0 0 10px 0;
  background-color: #fff;
  padding: 18px 0 10px 0;
}
.page-sidebar .sidebar-menu .menu-items > li ul.sub-menu > li {
  background: none;
  padding: 0px 20px 0 40px;
  margin-top: 1px;
}
.page-sidebar
  .sidebar-menu
  .menu-items
  > li
  ul.sub-menu
  > li:hover
  > .icon-thumbnail {
  color: #000;
}
.page-sidebar .sidebar-menu .menu-items > li ul.sub-menu > li > a {
  display: inline-block;
  padding: 5px 0px;
  font-size: 13px;
  font-family: Arial, sans-serif;
  white-space: normal;
}
.page-sidebar .sidebar-menu .menu-items > li ul.sub-menu > li .icon-thumbnail {
  width: 30px;
  height: 30px;
  line-height: 30px;
  margin: 0;
  background-color: #fff;
  font-size: 14px;
}
.page-sidebar .sidebar-menu .muted {
  color: #576775;
  opacity: 0.45;
}

.page-sidebar .icon-thumbnail [class^='bg-'],
.page-sidebar [class*='bg-'] {
  color: #fff;
}
[data-toggle-pin='sidebar'] > i:before {
  content: '\f10c';
}
.nav-item {
  &.active:nth-child(1){
    border-bottom : 3px solid #b1ddd3;
  }
  &.active:nth-child(2){
    border-bottom : 3px solid #b1ddd3;
  }
  &.active:nth-child(3){
    border-bottom : 3px solid #adb0d9;
  }
  &.active:nth-child(4){
    border-bottom : 3px solid #f4c0d9;
  }
  &.active:nth-child(5){
    border-bottom : 3px solid #ffc965;
  }
  &.active:nth-child(6){
    border-bottom : 3px solid #d6b679;
  }
  &.active:nth-child(7){
    border-bottom : 3px solid #b1ddd3;
  }
  &.active:nth-child(8){
    border-bottom : 3px solid #999;
  }
}
.nav-item a{
  &:after {
    content: '\00B7';
    position: relative;
    top: 0px;
    right: -7px;
    font-size: 19px;
  }
}
.nav-item:last-child {
  a{
    &:after {
      content : ""
    }
  }
  

}

.navbar-header {
  
  float: left;
  padding: 5px;
  text-align: center;
  width: 100%;
}
.navbar-brand {
  float: none;
}
.navbar-nav{
  font-family : "Arial", serif;
}

/* Style for our header texts
	* --------------------------------------- */
h1 {
  font-size: 5em;
  font-family: arial, helvetica;
  color: #fff;
  margin: 0;
  padding: 0;
}

/* Centered texts in each section
	* --------------------------------------- */
.section {
  text-align: center;
}

/* Backgrounds will cover all the section
	* --------------------------------------- */
.section {
  background-size: cover;
}

.slide {
  background-size: cover;
}

#acordos h1 {
  color: #000;
}

/*Adding background for the slides
	* --------------------------------------- */
#slide1 {
  background-image: url('../img/organizza_vf-09.jpg');
}
#slide2 {
  background-image: url('../img/organizza_vf-08.jpg');
}

.bg-light {
  background-color: rgba(200, 200, 200, 0.2) !important;
}

.title-subsection {
  top: 10em;
  left: 100px;
  position: absolute;
  text-align: left;
  h1 {
    line-height: 0.6em;
    font-family: 'didoBold';
    font-size: 8em;
    color: #f1f1f1;
  }
}

.ltext-subsection {
  width: 50%;
  position: relative;
  text-align: left;
  h1 {
    line-height: 1em;
    margin-left: 2em;
    margin-right: 2em;

    font-size: 2em;
    color: #333;
  }
}
.rtext-subsection {
  width: 50%;
  left: 50%;
  top: 35%;
  position: absolute;
  text-align: left;
  p {

  }
  h5 {

    font-size: 2em;
  }
}

.left-container{
  background-color :rgba(50,50,50,0.5);
  height : 100%;
  padding-top: 100px;
  p{
    color : #f1f1f1;
    font-size : 1.2em;
    font-weight : 600;
    margin : 1em;
    text-align : left;
  }
}
.left-container-simple{
  display : table;
  height : 100%;
  padding-left : 5em;
  padding-right : 5em;
  p{
    color : #f1f1f1;
    margin : 1em;
    text-align : left;
    display: table-cell;
    vertical-align: middle;
  }
}
@include media-breakpoint-down(sm) {
  .left-container-simple{
    padding-left : 4em;
  padding-right : 4em;
    p{
      padding-top : 10px;
      margin : 0px;
    }
  }
}
#especialidades {
  @include media-breakpoint-down(sm) {
    .title-subsection {
      display: none;
    }
  }
  .left-container {
    text-align: left;

    h1 {
      line-height: 1em;
      margin-right: 2em;
  
      font-size: 2em;
      color: #333;
    }
    @include media-breakpoint-down(sm) {
      padding-left: 20px;
      margin-top: 65px;
      h1 {
        font-size: 1.1em;
        margin-right: 10px;
      }
    }
  }
  .right-container {
    padding-right: 40px;
    text-align: left;
    p {
  
      margin-bottom: 0.7em;
    }
    h5 {
  
      font-size: 2em;
    }
    @include media-breakpoint-down(sm) {
      padding-right: 10px;

      h5 {
    
        font-size: 1.2em;
      }
      p {
        font-size: 0.9em;
      }
    }
  }
}
#analisesClinicas {
  .title-subsection {
    top: 5em;
    float: left;
    left: 100px;
    position: relative;
    margin-top: -200px;
    text-align: left;
  }
  .left-container {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    h1 {
      line-height: 0.8em;
      font-family: 'didoBold';
      font-size: 6em;
      color: #333;
    }
  }
  .right-container {
    padding-right: 40px;
    text-align: left;
    h5 {
  
      font-size: 2em;
      @include media-breakpoint-down(sm) {
        font-size: 1.2em;
        margin-down: 0.7em;
      }
    }
  }

  @include media-breakpoint-down(sm) {
    .title-subsection {
      display: none;
    }
    .left-container {
      h1 {
        font-size: 2em;
      }
    }
  }
  @include media-breakpoint-between(sm, md) {
    .left-container {
      h1 {
        font-size: 5em;
      }
    }
  }
}

.port-container {
  top: 6.5em;
  bottom: 1em;
  position: absolute;
  text-align: left;
  background: rgba(200, 200, 200, 0.1);
  width: 370px;
  padding: 20px;
  padding-left: 70px;
  @include media-breakpoint-down(sm) {
    padding: 10px;
    // width : 100%;
  }

  span {

    display: block;
    margin-top: 20px;
  }
  h1 {
    font-size: 2em;
    font-family: 'didoBold';
  }
  p {
    margin-top: 20px;
    font-size: 1.2em;
    font-family: 'didoRegularItalic';
  }

  &.inverted {
    background: rgba(33, 33, 33, 0.5);
    p {
      color: #f1f1f1;
    }
    h1 {
      color: #f1f1f1 !important;
    }
    span {
      color: #f1f1f1;
    }
  }
}

.fp-slidesNav.fp-bottom {
  left: 100px !important;
  right: auto;
  bottom: 100px;
}

.fp-controlArrow.fp-next {
  border: none !important;
  background: url('../img/chevron-right_white.svg') no-repeat;

  height: 50px;
  width: 50px;
}
.fp-controlArrow.fp-prev {
  border: none !important;
  background: url('../img/chevron-right_white.svg') no-repeat;
  transform: rotate(180deg);

  height: 50px;
  width: 50px;
}

.scroll-downs {
  position: absolute;

  right: 0;
  bottom: 20px;
  left: 0;
  margin: auto;

  width: 34px;
  height: 55px;
}
.mousey {
  width: 3px;
  padding: 10px 15px;
  height: 35px;
  border: 2px solid #f1f1f1;
  border-radius: 25px;
  opacity: 0.75;
  box-sizing: content-box;
}
.scroller {
  width: 3px;
  height: 10px;
  border-radius: 25%;
  background-color: #f1f1f1;
  animation-name: scroll;
  animation-duration: 2.2s;
  animation-timing-function: cubic-bezier(0.15, 0.41, 0.69, 0.94);
  animation-iteration-count: infinite;
}
@keyframes scroll {
  0% {
    opacity: 0;
  }
  10% {
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    transform: translateY(15px);
    opacity: 0;
  }
}
.navbar-toggler-icon {
  position: absolute;
}
.mylight-toggler {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255,255,255, 0.9)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E") !important;
}
.mydark-toggler {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0,0,0, 0.9)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E") !important;
}
@include media-breakpoint-down(sm) {
  .fp-slidesNav.fp-bottom {
    display: none;
  }
}
@include media-breakpoint-down(sm) {
  #clinica{
    background-position: center;
  }
}

.slick-track, .slick-list {
  height : 100%;
}

@include media-breakpoint-down(sm) {
  #clinica0{
    p{
      font-size : 0.9em;
      margin: 0.5em;
    }
  }
}

#equipa01 {
  li {
    margin: 8px;
  }
   h3 {
    margin: 8px;
  }
   h5 {
    margin: 8px;
  }
}

.slick-lightbox-close{
  z-index:1000000;
  width:60px;
  height:60px;
  top : 50px;
}

#marcacoes {
  text-align: left;
  h3 {
    padding-bottom: 50px;
    color: #b1ddd3;
  }
}