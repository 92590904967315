.analises-section{
    p{
        color : #f4c0d9;
        font-size : 3em;
        line-height:1em;
    }

}
.analises-container{
    margin-left : 60px;
    margin-right : 60px;
}

@include media-breakpoint-down(sm) {
    .analises-section{
        p{
            font-size : 2em;
            margin: 1.3em 1.3em;
            line-height:1.3em;
        }

    }
    .analises-container{
        margin-left : 10px;
        margin-right : 10px;
        margin-top : 40px;
        h3{
            font-size : 16px;
        }
        p{
            font-size : 13px;
        }
    }
}