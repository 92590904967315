.footer {

  bottom: 0;
  left: 0;
  right: 0;
  height: 80px;
  background-color: #787878;
  .copyright {
    color: #fff;
    font-size: 14px;
  }
  .icons__wrapper {
    list-style: none;
    .list__item {
      &__route {
        text-decoration: none;
        color: #fff;
      }
    }
  }
}

i:hover{
  color: rgba(255, 255, 255, 0.9)
}
