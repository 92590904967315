#contactos {

  .adress__wrapper {
    color: #999;
    .adress__boldText {
      font-size: 16px;
      margin-right: 5px;
      &__dot {
        margin-left: 5px;
      }
    }
    .adress__lightText {
      font-size: 16px;
      color : #999;
      font-weight : 200;
    }
  }
}
