.especialidade-section{
    height : 100%;
    p{
        color : #adb0d9;
        font-size : 3em;
        line-height: 1em;
    }
}
@include media-breakpoint-down(sm) {
  .especialidade-section{
      p{
          
          font-size : 2.2em;
          margin: 1.3em 1.3em;
          line-height: 1.3em;
      }
  }
}
#especialidades1{
  .analises-container{
    p{
      color: #f1f1f1;
    }
  }
}
#accordion, #accordion2 {
  margin: 50px;
  button{
    font-size: 22px; 
  }
  .card-body{
    margin-left: 14px;
  }
  color: #f1f1f1;
   .card,
   .card:last-child .card-header {
    border: none;
    background: transparent;
  }
   .card-header {
    border-bottom-color: transparent;
    background: transparent !important;
  }
   .btn-link:hover,
   .btn-link:focus {
    text-decoration: none;
  }
}
#accordion-mobile, #accordion2-mobile {
  button{
    font-size: 18px; 
  }
  color: #f1f1f1;
  .card-body{
    font-size: 12px;
    padding-top: 0px;
  }
   .card,
   .card:last-child .card-header {
    border: none;
    background: transparent;
  }
   .card-header {
    padding-left: 0px; 
    border-bottom-color: transparent;
    background: transparent !important;
  }
   .btn-link:hover,
   .btn-link:focus {
    text-decoration: none;
  }
}