//====================================
// Global Imports
//====================================

// 1- Import bootstrap framework
@import '1-frameworks/index';

// 2 - Import plugins
@import '2-plugins/index';

// 3 - Import base style, custom variables and mixins
@import '3-base/index';

// 4 - Import modules
@import '4-modules/index';

// 5 - Import layout  styles
@import '5-layout/index';

@import 'organizza';

// //////////////\\\\\\\\\\\\\\\ \\
//             D32                \\
// //////////////\\\\\\\\\\\\\\\\\ \\

@import './D32/_reset';
@import './D32/_navbar';
@import './D32/_clinica';
@import './D32/_especialidades';
@import './D32/_analisesClinicas';
@import './D32/_acordos';
@import './D32/_marcacoes';
@import './D32/_contactos';
@import './D32/_footer';

.btn-primary.disabled,.btn-primary:disabled {
    background-color: #b1ddd3;
    opacity: 0.75;
}

.list-unstyled{ margin: 0;}
.help-block.with-errors {
    color: red;
}

#equipa{
    .slide{
        p{margin: 0px;}
    }
}